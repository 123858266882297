import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import { EVENTINSTANCE_GET_BY_ID } from 'GraphQL/EventInstances/queries';
import { EVENTINSTANCE_DIVISION_UPDATE } from 'GraphQL/EventInstances/mutations';
import { EVENTINSTANCE_UPDATE } from 'GraphQL/EventInstances/mutations';
import { useSnackbar } from 'notistack';
import LoaderWrap from 'LoaderWrap';
import { Alert, AlertTitle, Collapse, Grid } from '@mui/material';
import FormikTextInput from 'components/FormikTextInput';
import MessageTemplateSelector from '../Communications/ScheduledMessages/ScheduledMessageDetails/MessageTemplateSelector';
import ArgonBox from 'components/ArgonBox';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormikNumberInput from 'components/FormikNumberInput';
import ArgonEditor from 'components/ArgonEditor';
import ArgonButton from 'components/ArgonButton';
import DialogWrapper from 'components/DialogWrapper';
import PreviewScheduledMessage from '../Communications/ScheduledMessages/PreviewScheduledMessage';
import { nanoid } from 'nanoid';
import { MESSAGETEMPLATE_GET_BY_ID } from 'GraphQL/MessageTemplates/queries';
import ArgonSelect from 'components/ArgonSelect';
import { EVENTINSTANCES_GET_BY_EVENTID } from 'GraphQL/EventInstances/queries';
import selectData from 'options/selectData';
import FolderWrapper from 'components/FolderWrapper';
import AutoInvitationDivisionMapping from './DivisionMapping/AutoInvitationDivisionMapping';
import ArgonTypography from 'components/ArgonTypography';

import {
  invitationSubjectTemplate,
  invitationBodyTemplate
} from './invitationMessageTemplates';
import { useTranslation } from 'react-i18next';
import { ORGANIZATION_GET_BY_ID } from 'GraphQL/Organization/queries';
import { initiateAutoInvitations } from 'services/REST_API/participants';

function AutoInvitationSettings({ eventInstanceId }) {
  const { enqueueSnackbar } = useSnackbar();
  const { i18n, t } = useTranslation();

  const [uniqueEditorId] = useState(nanoid().toString());

  const [eventName, setEventName] = useState('');

  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [messageTemplateIsValid, setMessageTemplateIsValid] = useState();

  const [showMessagePreview, setShowMessagePreview] = useState(false);

  const [previousEventInstancesOptions, setPreviousEventInstancesOptions] =
    useState([]);

  const [
    preceedingEventInstanceDivisions,
    setPreceedingEventInstanceDivisions
  ] = useState([]);

  const [gqlFetchEventInstance, { loading, data }] = useLazyQuery(
    EVENTINSTANCE_GET_BY_ID,
    {
      onCompleted: async (data) => {
        console.log('AutoInvitationSettings: eventInstance fetched:', data);
        fetchEventInstancesByEventId({
          variables: { eventId: data?.eventInstance?.event._id }
        });

        fetchOrganization({
          variables: {
            id: data?.eventInstance?.organizationId
          }
        });

        const tempEventName =
          data?.eventInstance?.event?.name +
          ' ' +
          (data?.eventInstance?.name ||
            selectData.eventInstanceTypes.find(
              (eit) => eit?.value === data?.eventInstance?.type
            )?.label);

        console.log('EventName:', tempEventName);

        await formik.setFieldValue(
          'messageSubject',
          invitationSubjectTemplate(tempEventName)
        );

        await formik.setFieldValue(
          'messageBody',
          invitationBodyTemplate({
            eventName: tempEventName,
            startDate: data?.eventInstance?.startDate,
            endDate: data?.eventInstance?.endDate,

            expiryInDays: formik.values.inviteExpiryDays,
            organizerName: formik.values.messageSenderName,
            locale: i18n?.language
          })
        );

        await formik.setFieldValue(
          'organization',
          data?.eventInstance?.organizationId
        );
      },
      onError: (error) => {
        console.error(
          'AutoInvitationSettings: error fetching eventInstance:',
          error
        );
      },
      fetchPolicy: 'cache-and-network'
    }
  );

  const [gqlUpdateEventInstance] = useMutation(EVENTINSTANCE_UPDATE);

  const [gqlUpdateDivision] = useMutation(EVENTINSTANCE_DIVISION_UPDATE, {
    onCompleted: (data) => {
      enqueueSnackbar('Division updated', {
        variant: 'success'
      });
    },
    onError: (error) => {
      console.error('AutoInvitationSettings: Error updating division:', error);
      enqueueSnackbar('Error updating division', {
        variant: 'error'
      });
    }
  });

  const [fetchOrganization, { loading: loadingOrganization }] = useLazyQuery(
    ORGANIZATION_GET_BY_ID,
    {
      variables: {
        id: data?.eventInstance?.organizationId
      },

      onCompleted: async (data) => {
        console.log('AutoInvitationSettings: Organization fetched:', data);
        await formik.setFieldValue(
          'messageSenderName',
          data?.organization?.name
        );
        await formik.setFieldValue(
          'messageSenderEmail',
          data?.organization?.email
        );
      },
      onError: (error) => {
        console.error(
          'AutoInvitationSettings: Error fetching organization:',
          error
        );
      }
    }
  );

  const [fetchEventInstancesByEventId, { loading: allEventInstancesLoading }] =
    useLazyQuery(EVENTINSTANCES_GET_BY_EVENTID, {
      onCompleted: async (data) => {
        console.log(
          'AutoInvitationSettings: Event Instances for Event received from Database: ',
          data
        );

        const temp = [];

        data?.eventInstances?.forEach((instance) => {
          if (instance._id.toString() !== eventInstanceId.toString()) {
            temp.push({
              ...instance,
              value: instance?._id,
              label:
                instance?.name ||
                selectData.eventInstanceTypes.find(
                  (eit) => eit.value === instance.type
                )?.label
            });
          }
        });
        console.log(
          'AutoInvitationSettings: setting options for previous eventInstances:',
          temp
        );
        setPreviousEventInstancesOptions(temp);
      },
      onError: (error) => {
        console.error(
          'AutoInvitationSettings: Error getting event instances for event from Database: ',
          error
        );
      }
    });

  const [fetchMessageTemplate, { loading: loadingMessageTemplate }] =
    useLazyQuery(MESSAGETEMPLATE_GET_BY_ID, {
      onCompleted: (data) => {
        console.log(
          'AutoInvitationSettings: received MessageTemplate from Database: ',
          data
        );
        // const { name, html, design } = data.messagetemplate;

        // console.log('PreviewScheduledMessage: Loading design...', design);
      },
      onError: (error) => {
        console.error(
          'AutoInvitationSettings: Error receiving MessageTemplate:',
          error
        );
      }
    });

  const updateAutoInvitationSettings = async () => {
    setUpdateInProgress(true);
    await gqlUpdateEventInstance({
      variables: {
        eventInstanceId: formik.values.eventInstanceId,
        data: {
          // invitationsDefaults: {
          //   messageSubject: formik.values.messageSubject,
          //   messageBody: formik.values.messageBody,
          //   messageTemplateId: formik.values.messageTemplateId,
          //   inviteExpiryDays: formik.values.inviteExpiryDays,
          //   messageSenderName: formik.values.messageSenderName,
          //   messageSenderEmail: formik.values.messageSenderEmail
          // }
        }
      }
    })
      .then(async (data) => {
        console.log('result from update operation', data);

        enqueueSnackbar('Your changes have been saved.', {
          variant: 'success'
        });
        // Swal.fire('Success!', 'Your profile has been updated!', 'success');
      })
      .catch((err) => {
        console.error('error', err);
      })
      .finally(() => {
        setUpdateInProgress(false);
      });
  };

  useEffect(() => {
    gqlFetchEventInstance({
      variables: {
        eventInstanceId: eventInstanceId
      }
    });
  }, [eventInstanceId]);

  // await gqlUpdateDivision({
  //   variables: {
  //     data: {
  //       eventInstanceId: eventInstanceId,
  //       division: updatedDivision
  //     }
  //   }
  // });

  // let eventName =
  //   data?.eventInstance?.event && data?.eventInstance?.event.toString() + ' ';
  // eventName = eventName + data?.eventInstance?.name;

  const checkIfMessageTemplateIsValidForInvitations = (messageTemplateId) => {
    if (messageTemplateId)
      fetchMessageTemplate({
        variables: { id: messageTemplateId }
      }).then((result) => {
        // result?.data?.messagetemplate?.html?.includes(
        //   '{{{recipientFirstName}}}'
        // ) &&
        result?.data?.messagetemplate?.html?.includes('{{{messageContent}}}') &&
        result?.data?.messagetemplate?.html?.includes('{{{button}}}')
          ? setMessageTemplateIsValid(true)
          : setMessageTemplateIsValid(false);
      });
    else setMessageTemplateIsValid(true);
  };

  const validationSchema = Yup.object({
    // email: yup.string().email('Invalid email').required('Required'),
    // firstName: yup.string().required('Required'),
    // lastName: yup.string().required('Required')
    eventInstanceToId: Yup.string().required('Required'),
    eventInstanceFromId: Yup.string().required('Required'),
    messageTemplateId: Yup.string(),
    messageSubject: Yup.string().required('Required'),
    messageBody: Yup.string().required('Required'),
    inviteExpiryDays: Yup.number().min(0).required('Required'),
    eventInstanceId: Yup.string().required('Required'),
    organization: Yup.string().required('Required'),
    messageSenderEmail: Yup.string().email().required('Required'),
    messageSenderName: Yup.string().required('Required'),

    invitesPerDivision: Yup.array()
      .of(
        Yup.object().shape({
          divisionFromId: Yup.string(),
          divisionFromName: Yup.string(),
          divisionToId: Yup.string(),
          divisionToName: Yup.string(),
          inviteCount: Yup.number().min(0).required('Required'),
          invitationMethod: Yup.string().required('Required')
        })
      )
      .required('Required')
  });

  const formik = useFormik({
    validationSchema,
    initialValues: {
      eventInstanceToId: eventInstanceId,
      eventInstanceFromId: '',
      messageTemplateId: process.env.REACT_APP_STANDARD_MESSAGE_TEMPLATE_ID,
      messageSubject: invitationSubjectTemplate(eventName),
      messageBody: '',
      inviteExpiryDays: 7,
      eventInstanceId: eventInstanceId,
      organization: data?.eventInstance?.organization?._id,
      messageSenderEmail: '',
      messageSenderName: '',
      invitesPerDivision: []
    },
    onSubmit: async (values) => {
      alert('Form submitted: ' + JSON.stringify(values, null, 2));
      console.log(values);

      await initiateAutoInvitations({
        inviteToEventInstanceId: values.eventInstanceToId,
        inviteFromEventInstanceId: values.eventInstanceFromId,
        inviteExpiryInDays: values.inviteExpiryDays,
        inviteSubject: values.messageSubject,
        inviteMessageBody: values.messageBody,
        messageTemplateId: values.messageTemplateId,
        invitesPerDivision: values.invitesPerDivision,
        messageSenderName: values.messageSenderName,
        messageSenderEmail: values.messageSenderEmail
      })
        .then((result) => {
          console.log(
            'AutoInvitationSettings: initiateAutoInvitations: ',
            result
          );
          enqueueSnackbar('Auto-Invitations initiated', {
            variant: 'success'
          });
        })
        .catch((error) => {
          console.error(
            'AutoInvitationSettings: initiateAutoInvitations: ',
            error
          );
          enqueueSnackbar('Error initiating Auto-Invitations', {
            variant: 'error'
          });
        });
    }
  });

  useEffect(() => {
    checkIfMessageTemplateIsValidForInvitations(
      formik.values.messageTemplateId
    );
  }, [formik.values.messageTemplateId]);

  console.log(
    'AutoInvitationSettings: divisions:',
    data?.eventInstance?.divisions
  );

  const handleDivisionMappingChanged = async (newDivisionMapping) => {
    console.log('handleDivisionMappingChanged: ', newDivisionMapping);

    // now transform input to required format:
    const transformedInvitationsPerDivision = newDivisionMapping.map((d) => ({
      divisionFromId: d?.sourceDivisionId,
      divisionFromName: d?.sourceDivisionName,
      divisionToId: d?.targetDivisionId,
      divisionToName: d?.targetDivisionName,
      inviteCount: Number(d?.plannedInvitations),
      invitationMethod: d?.invitationMethod
    }));

    console.log(
      'handleDivisionMappingChanged: transformedInvitationsPerDivision: ',
      transformedInvitationsPerDivision
    );

    await formik.setFieldValue(
      'invitesPerDivision',
      transformedInvitationsPerDivision
    );
  };

  console.log('AutoInvitationSettings: formik.errors: ', formik.errors);

  const inputSize = 'small';

  return (
    <>
      <LoaderWrap loading={loading || loadingOrganization}>
        <ArgonBox mb={3}>
          {/* <FolderWrapper title="Division Mapping" isOpen> */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ArgonTypography variant="h6" fontWeight="bold" color="text">
                Source Event Instance
              </ArgonTypography>

              <ArgonBox gap={2}>
                <ArgonSelect
                  id="eventInstanceFrom"
                  name="eventInstanceFrom"
                  overflow
                  loading={allEventInstancesLoading}
                  options={previousEventInstancesOptions}
                  label="Select a preceeding Event Instance to invite participants from"
                  onChange={(option) => {
                    formik.setFieldValue('eventInstanceFromId', option.value);
                    setPreceedingEventInstanceDivisions(
                      previousEventInstancesOptions
                        .find((o) => o?.value.toString() === option.value)
                        ?.divisions?.map((d) => ({
                          ...d,
                          value: d._id,
                          label: d.name
                        }))
                    );
                    console.log(
                      'Setting preceedingEventInstanceDivisions: ',
                      previousEventInstancesOptions.find(
                        (o) => o?.value.toString() === option.value
                      )?.divisions
                    );
                  }}
                  value={previousEventInstancesOptions.find(
                    (o) =>
                      o?.value.toString() === formik.values.eventInstanceFromId
                  )}
                />
                <Collapse in={preceedingEventInstanceDivisions.length > 0}>
                  <ArgonBox mt={4}>
                    <ArgonTypography
                      variant="h6"
                      fontWeight="bold"
                      color="text">
                      Map Divisions
                    </ArgonTypography>
                    <AutoInvitationDivisionMapping
                      key={formik.values?.eventInstanceFromId}
                      sourceEventInstanceId={formik.values?.eventInstanceFromId}
                      divisions={data?.eventInstance?.divisions}
                      predecessorDivisions={preceedingEventInstanceDivisions}
                      onChange={handleDivisionMappingChanged}
                    />

                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ArgonBox mt={4} />
                        <ArgonTypography
                          variant="h6"
                          fontWeight="bold"
                          color="text">
                          Message settings
                        </ArgonTypography>
                      </Grid>
                      {/* <Grid item xs={12}>
                <ArgonSelect
                  id="eventInstanceFrom"
                  name="eventInstanceFrom"
                  loading={allEventInstancesLoading}
                  options={previousEventInstancesOptions}
                  label="Select Event Instance to invite from"
                  onChange={(option) => {
                    formik.setFieldValue('eventInstanceFromId', option.value);
                    setPreceedingEventInstanceDivisions(
                      previousEventInstancesOptions
                        .find((o) => o?.value.toString() === option.value)
                        ?.divisions?.map((d) => ({
                          ...d,
                          value: d._id,
                          label: d.name
                        }))
                    );
                    console.log(
                      'Setting preceedingEventInstanceDivisions: ',
                      previousEventInstancesOptions.find(
                        (o) => o?.value.toString() === option.value
                      )?.divisions
                    );
                  }}
                  value={previousEventInstancesOptions.find(
                    (o) =>
                      o?.value.toString() === formik.values.eventInstanceFromId
                  )}
                />
              </Grid> */}

                      <Grid item xs={9}>
                        <MessageTemplateSelector
                          id="messageTemplateId"
                          name="messageTemplateId"
                          key={formik.values?.eventInstanceId}
                          formik={formik}
                          label="Select Message Template (optional)"
                          organizationId={formik.values?.organization}
                        />
                        <Collapse in={!messageTemplateIsValid}>
                          <ArgonBox mt={2}>
                            <Alert severity="error">
                              <AlertTitle>
                                Message template not valid
                              </AlertTitle>
                              This message template does not contain all of the
                              required variables (recipientFirstName,
                              recipientLastName, messageContent, button). Check
                              the template in the editor and make sure it
                              contains all of the required variables. Or choose
                              another template.
                            </Alert>
                          </ArgonBox>
                        </Collapse>
                      </Grid>
                      <Grid item xs={3}>
                        <FormikNumberInput
                          id="inviteExpiryDays"
                          name="inviteExpiryDays"
                          label="Invite will expire in"
                          formik={formik}
                          suffix=" days"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <LoaderWrap loading={loading}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <FormikTextInput
                                id="messageSubject"
                                name="messageSubject"
                                label="Email Subject & Body"
                                formik={formik}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <ArgonEditor
                                uniqueKey={uniqueEditorId}
                                id={uniqueEditorId}
                                name={uniqueEditorId}
                                value={formik.values.messageBody}
                                onChange={(value) =>
                                  formik.setFieldValue('messageBody', value)
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <FormikTextInput
                                id="messageSenderName"
                                name="messageSenderName"
                                label="Name of the sender (email from)"
                                formik={formik}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <FormikTextInput
                                id="messageSenderEmail"
                                name="messageSenderEmail"
                                label="Email of the sender (reply-to)"
                                formik={formik}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Collapse
                                in={formik.values.messageTemplateId !== null}>
                                <ArgonButton
                                  variant="outlined"
                                  color="secondary"
                                  size={inputSize}
                                  onClick={() => setShowMessagePreview(true)}
                                  disabled={
                                    formik.isSubmitting ||
                                    !messageTemplateIsValid
                                  }
                                  loadingColor="dark">
                                  Preview Invitation Email
                                </ArgonButton>
                              </Collapse>
                            </Grid>
                          </Grid>
                        </LoaderWrap>
                      </Grid>
                    </Grid>
                  </ArgonBox>
                </Collapse>
              </ArgonBox>
            </Grid>
          </Grid>
        </ArgonBox>
        {/* </FolderWrapper> */}

        {/* <FolderWrapper
            title="Auto-Invitation Settings"
            isOpen
            startIcon="success"> */}

        <ArgonBox display="flex" justifyContent="flex-end" mb={3}>
          <ArgonButton
            size={inputSize}
            variant="gradient"
            color="success"
            loading={formik.isSubmitting}
            loadingColor="white"
            onClick={formik.handleSubmit}>
            Activate Automatic Invitations
          </ArgonButton>
        </ArgonBox>
      </LoaderWrap>

      <DialogWrapper
        open={showMessagePreview}
        title={'Preview: ' + formik.values.messageSubject}
        onClose={() => setShowMessagePreview(false)}
        fullWidth
        maxWidth="lg">
        <PreviewScheduledMessage
          // scheduledMessage={data?.scheduledMessage}
          scheduledMessage={{
            messageHeadline: formik.values.messageSubject,
            messageBody: formik.values.messageBody,
            email: formik.values.email,
            recipientFirstName: formik.values.firstName,
            recipientLastName: formik.values.lastName,
            messageTemplateId: formik.values.messageTemplateId
          }}
          messageTemplateId={formik.values?.messageTemplateId}
          eventInstanceId={eventInstanceId}
          simulateAcceptDeclineButtons={true}
        />
      </DialogWrapper>
    </>
  );
}

AutoInvitationSettings.propTypes = {};

export default AutoInvitationSettings;
