import React, { useState } from 'react';
import { styled } from '@mui/styles';
import PropTypes from 'prop-types';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse, IconButton } from '@mui/material';
import ArgonBox from 'components/ArgonBox';
import ArgonButton from 'components/ArgonButton';
import ArgonTypography from 'components/ArgonTypography';

// import icons from mui
import DoneIcon from '@mui/icons-material/Done';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import { useArgonController } from 'context';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

function FolderWrapper({ title, children, isOpen, noGutter, startIcon }) {
  const [controller] = useArgonController();
  const { darkMode } = controller;

  const [expanded, setExpanded] = useState(isOpen || false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const showStartIcon = () => {
    switch (startIcon) {
      case 'warning':
        return <WarningAmberIcon color="warning" fontSize="medium" />;
      case 'info':
        return <InfoOutlinedIcon color="info" fontSize="medium" />;
      case 'success':
        return <CheckCircleOutlineIcon color="success" fontSize="medium" />;
      case 'error':
        return <ErrorOutlineOutlinedIcon color="error" fontSize="medium" />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <ArgonBox
        component="li"
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        borderRadius="lg"
        pl={3}
        pr={3}
        mb={noGutter ? 0 : 1}
        mt={2}
        sx={({ palette: { grey, background } }) => ({
          backgroundColor: darkMode ? background.default : grey[100]
        })}>
        <ArgonBox width="100%" display="flex" flexDirection="column">
          <ArgonBox
            display="flex"
            justifyContent="space-between"
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            flexDirection={{ xs: 'column', sm: 'row' }}
            mb={1}
            mt={1}
            onClick={handleExpandClick}
            style={{ cursor: 'pointer' }}>
            <ArgonTypography
              variant="button"
              fontWeight="medium"
              textTransform="capitalize">
              <ArgonBox
                display="flex"
                color="inherit"
                gap={1}
                alignItems="center">
                {showStartIcon()}
                {title}
              </ArgonBox>
            </ArgonTypography>

            <ArgonBox
              display="flex"
              alignItems="center"
              mt={{ xs: 2, sm: 0 }}
              ml={{ xs: -1.5, sm: 0 }}>
              {/* <ArgonBox mr={1}>
              <ArgonButton variant="text" color="error">
                <Icon>delete</Icon>&nbsp;Delete
              </ArgonButton>
            </ArgonBox> */}
              {/* <ArgonButton variant="text" color="dark">
              <Icon>edit</Icon>
            </ArgonButton> */}

              <ArgonButton variant="text" color="dark">
                {/* <Icon>info</Icon> */}
                <ExpandMore
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more">
                  <ExpandMoreIcon />
                </ExpandMore>
              </ArgonButton>
            </ArgonBox>
          </ArgonBox>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <ArgonBox pb={3}>{children}</ArgonBox>
          </Collapse>
        </ArgonBox>
      </ArgonBox>
    </>
  );
}

FolderWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  noGutter: PropTypes.bool,
  startIcon: PropTypes.oneOf(['warning', 'info', 'success', 'error'])
};

export default FolderWrapper;
