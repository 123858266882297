import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ArgonTypography from 'components/ArgonTypography';
import ArgonBox from 'components/ArgonBox';
import ArgonSelect from 'components/ArgonSelect';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import NumberInput from 'components/NumberInput';
import ArgonProgress from 'components/ArgonProgress';
import { getRegistrationsByDivisionsForEventInstance } from 'services/restApi';
import { PulseLoader } from 'react-spinners';

const methodOptions = [{ value: 'top', label: 'Top ranks with fill up' }];

const DivisionRow = ({
  sourceEventInstanceId,
  targetDivision,
  availableSourceDivisions,
  selectedSourceDivision,
  invitationMethod,
  plannedInvitations,
  showError,
  showWarning,
  onChange
}) => {
  const [selectedMethodOption, setSelectedMethodOption] = useState(
    // methodOptions.find((mo) => mo.value === invitationMethod) ||
    methodOptions[0]
  );

  // const [matchedDivision, setMatchedDivision] = useState(selectedSourceDivision);

  const [registrationCountLoading, setRegistrationCountLoading] =
    useState(false);
  const [
    registrationCountOfSourceDivision,
    setRegistrationCountOfSourceDivision
  ] = useState();

  const [numberOfAthletesToBeInvited, setNumberOfAthletesToBeInvited] =
    useState(plannedInvitations);

  useEffect(() => {
    // fetch registration count of source division
    if (sourceEventInstanceId) {
      setRegistrationCountLoading(true);
      getRegistrationsByDivisionsForEventInstance({
        eventInstanceId: sourceEventInstanceId
      })
        .then((result) => {
          console.log(
            'DivisionRow: getRegistrationsByDivisionsForEventInstance: result: ',
            result
          );

          let count = 'n/a';
          if (result?.registrationStatusData)
            count =
              result.registrationStatusData?.find(
                (rsd) => rsd.divisionId === selectedSourceDivision?._id
              )?.spotsTaken || '0';
          setRegistrationCountOfSourceDivision(count);
        })
        .finally(() => {
          setRegistrationCountLoading(false);
        });
    }
  }, [sourceEventInstanceId]);

  const handleDivisionChanged = (newOption) => {
    // setMatchedDivision(newOption);
    // console.log('DivisionRow: handleDivisionChanged: newOption: ', newOption);
    onChange &&
      onChange({
        sourceDivision: newOption,
        targetDivision: targetDivision?._id,
        invitationMethod: selectedMethodOption.value,
        plannedInvitations: numberOfAthletesToBeInvited
      });
  };

  const handleInvitationMethodChanged = (newOption) => {
    // console.log('New Invitation Method chosen: ', newOption);
    setSelectedMethodOption(newOption);
    onChange &&
      onChange({
        sourceDivision: selectedSourceDivision,
        targetDivision: targetDivision?._id,
        invitationMethod: newOption?.value,
        plannedInvitations: numberOfAthletesToBeInvited
      });
  };

  const handleInvitationCountChanged = (newCount) => {
    console.log('Planned Invitations changed:', newCount);

    setNumberOfAthletesToBeInvited(newCount);
    onChange &&
      onChange({
        sourceDivision: selectedSourceDivision,
        targetDivision: targetDivision?._id,
        invitationMethod: selectedMethodOption?.value,
        plannedInvitations: newCount
      });
  };

  console.log('Show Error: ', showError);
  console.log(
    'DivisionRow: selectedSourceDivision: plannedInvitations:',
    plannedInvitations,
    typeof plannedInvitations
  );

  // const inputSize = undefined;
  const inputSize = 'small';

  return (
    <TableRow key={targetDivision?._id}>
      <TableCell style={{ paddingLeft: '0px' }}>
        <ArgonTypography variant="caption" fontWeight="bold" color="dark">
          {targetDivision?.name}
        </ArgonTypography>
      </TableCell>
      <TableCell style={{ paddingRight: '0px' }}>
        <ArgonBox>
          <ArgonSelect
            options={availableSourceDivisions}
            onChange={handleDivisionChanged}
            value={selectedSourceDivision}
            success={selectedSourceDivision}
            // error={showError}
            isClearable
            size={inputSize}
            error={!selectedSourceDivision}
          />
          {showError && (
            <ArgonBox mt={-0.5} ml={0.5}>
              <ArgonTypography
                variant="caption"
                fontWeight="regular"
                color="error">
                {showError}
              </ArgonTypography>
            </ArgonBox>
          )}
          {showWarning && (
            <ArgonBox mt={-0.5} ml={0.5}>
              <ArgonTypography
                variant="caption"
                fontWeight="regular"
                color="warning">
                {showWarning}
              </ArgonTypography>
            </ArgonBox>
          )}
        </ArgonBox>
      </TableCell>
      <TableCell style={{ paddingRight: '0px' }} align="left">
        <ArgonTypography variant="regular" fontWeight="regular" color="dark">
          {registrationCountLoading ? (
            <PulseLoader />
          ) : (
            registrationCountOfSourceDivision
          )}
        </ArgonTypography>
      </TableCell>
      {/* <TableCell style={{ paddingRight: '0px' }}>
        <ArgonSelect />
      </TableCell> */}
      <TableCell style={{ paddingRight: '0px' }}>
        <ArgonSelect
          options={methodOptions}
          size={inputSize}
          value={selectedMethodOption}
          onChange={handleInvitationMethodChanged}
          error={!selectedMethodOption}
        />
      </TableCell>
      <TableCell style={{ paddingRight: '0px' }}>
        <NumberInput
          size={inputSize}
          maxValue={registrationCountOfSourceDivision}
          value={plannedInvitations}
          onChange={handleInvitationCountChanged}
          // error={plannedInvitations === undefined || plannedInvitations === ''}
          error={selectedSourceDivision && plannedInvitations === undefined}
        />
      </TableCell>
      <TableCell style={{ paddingRight: '0px' }}>
        <ArgonProgress size={inputSize} value={0} label={'X / Y'} />
      </TableCell>
    </TableRow>
  );
};

function AutoInvitationDivisionMapping({
  sourceEventInstanceId,
  targetEventInstanceId,
  divisions,
  predecessorDivisions,
  onChange
}) {
  // console.log('AutoInvitationDivisionMapping: divisions: ', divisions);
  // console.log(
  //   'AutoInvitationDivisionMapping: predecessorDivisions: ',
  //   predecessorDivisions
  // );

  const [availableSourceDivisions, setAvailableSourceDivisions] = useState(
    predecessorDivisions || []
  );
  const [availablTargetDvisisions, setAvailableTargetDivisions] = useState(
    divisions || []
  );

  const [mappedDivisions, setMappedDivisions] = useState([]);

  // useEffect(() => {
  //   // setUnmappedPredecessorDivisions(predecessorDivisions);

  // //   setMappedDivisions(
  // //     matchingDivisionsByName({
  // //       sourceDivisions: predecessorDivisions,
  // //       targetDivisions: divisions
  // //     })
  // //   );
  // }, [predecessorDivisions, divisions]);

  // useEffect(() => {
  //   setAvailableSourceDivisions(
  //     predecessorDivisions.map((division) => ({
  //       ...division,
  //       value: division._id,
  //       label: division.name,
  //       disabled: true
  //     }))
  //   );
  // }, [predecessorDivisions]);

  useEffect(() => {
    const mappedDivisions = matchingDivisionsByName({
      sourceDivisions: predecessorDivisions,
      targetDivisions: divisions
    });

    setAvailableTargetDivisions(
      divisions?.map((division) => ({
        ...division,
        value: division._id,
        label: division.name,
        sourceDivision: mappedDivisions.find(
          (md) => md.targetDivision === division._id
        )?.sourceDivision
      }))
    );

    setAvailableSourceDivisions(
      predecessorDivisions?.map((division) => ({
        ...division,
        value: division._id,
        label: division.name
      }))
    );
  }, [predecessorDivisions, divisions]);

  const matchingDivisionsByName = ({ sourceDivisions, targetDivisions }) => {
    const matchedDivisions = [];

    targetDivisions?.forEach((targetDivision) => {
      const matchingDivision = sourceDivisions.find(
        (division) => division.name === targetDivision.name
      );

      if (matchingDivision) {
        matchedDivisions.push({
          targetDivision: targetDivision?._id, // participants will be invited to this division
          sourceDivision: matchingDivision?._id // from preceeding event instance's division
        });
      }
    });
    console.log('Divisions matched by name: ', matchedDivisions);
    return matchedDivisions;
  };

  const handleDivisionMapped = ({
    targetDivision,
    sourceDivision,
    plannedInvitations,
    invitationMethod
  }) => {
    console.log(
      'AutoInvitationDivisionMapping: handleDivisionMapped: targetDivision: ',
      targetDivision
    );
    console.log(
      'AutoInvitationDivisionMapping: handleDivisionMapped: sourceDivision: ',
      sourceDivision
    );
    console.log(
      'AutoInvitationDivisionMapping: handleDivisionMapped: plannedInvitations: ',
      plannedInvitations
    );
    // replace the division in availablTargetDvisisions with the new sourceDivision in place of the old one
    const newTargetDivisions = availablTargetDvisisions.map((division) => {
      if (division._id === targetDivision) {
        return {
          ...division,
          sourceDivision: sourceDivision?._id,
          sourceDivisionId: sourceDivision?._id,
          sourceDivisionName: sourceDivision?.name,
          targetDivisionId: targetDivision,
          targetDivisionName: availablTargetDvisisions.find(
            (td) => td._id.toString() === targetDivision.toString()
          )?.name,
          plannedInvitations: plannedInvitations,
          invitationMethod: invitationMethod
        };
      }
      return division;
    });
    console.log('Updated target divisions:', newTargetDivisions);

    setAvailableTargetDivisions(newTargetDivisions);

    onChange && onChange(newTargetDivisions);
  };
  const inputSize = undefined;

  return (
    <>
      <Table>
        {/* <TableHead> */}
        <TableRow>
          <TableCell style={{ paddingLeft: '0px' }}>
            <ArgonTypography variant="caption" fontWeight="bold" color="dark">
              Invite To Division
            </ArgonTypography>
          </TableCell>
          <TableCell style={{ paddingRight: '0px', width: '250px' }}>
            <ArgonTypography variant="caption" fontWeight="bold" color="dark">
              From Source Division
            </ArgonTypography>
          </TableCell>
          {/* <TableCell style={{ paddingRight: '0px' }}>
            <ArgonTypography variant="caption" fontWeight="bold" color="dark">
              Method of Selection
            </ArgonTypography>
          </TableCell> */}
          <TableCell style={{ paddingRight: '0px', width: '100px' }}>
            <ArgonTypography variant="caption" fontWeight="bold" color="dark">
              Registrations in Source Divsion
            </ArgonTypography>
          </TableCell>
          <TableCell style={{ paddingRight: '0px', width: '250px' }}>
            <ArgonTypography variant="caption" fontWeight="bold" color="dark">
              Selection Method
            </ArgonTypography>
          </TableCell>
          <TableCell style={{ paddingRight: '0px', width: '150px' }}>
            <ArgonTypography variant="caption" fontWeight="bold" color="dark">
              No. of Invitations
            </ArgonTypography>
          </TableCell>
          <TableCell style={{ paddingRight: '0px', width: '250px' }}>
            <ArgonTypography variant="caption" fontWeight="bold" color="dark">
              Progress
            </ArgonTypography>
          </TableCell>
        </TableRow>
        {/* </TableHead>
        <TableBody> */}
        {availablTargetDvisisions
          // ?.sort((a, b) => a?.name?.localeCompare(b?.name))
          ?.map((division) => {
            console.log('Rendering Division: ', division);

            return (
              <DivisionRow
                key={division?._id + division?.sourceDivision}
                sourceEventInstanceId={sourceEventInstanceId}
                targetDivision={division}
                availableSourceDivisions={availableSourceDivisions}
                selectedSourceDivision={availableSourceDivisions.find(
                  (asd) =>
                    asd?._id?.toString() ===
                    division?.sourceDivision?.toString()
                )}
                // showWarning={
                //   division?.sourceDivision === undefined
                //     ? 'Should be mapped to a division, leave empty to not invite into this division'
                //     : undefined
                // }
                showError={
                  availablTargetDvisisions.filter(
                    (atd) =>
                      atd?.sourceDivision?.toString() ===
                      division?.sourceDivision?.toString()
                  ).length > 1
                    ? 'Already mapped to another division'
                    : undefined
                }
                plannedInvitations={division?.plannedInvitations}
                onChange={handleDivisionMapped}
              />
            );
          })}
        {(!divisions || divisions.length === 0) && (
          <TableRow>
            <TableCell colSpan={2} align="center">
              <ArgonTypography
                variant="regular"
                fontWeight="regular"
                color="dark">
                No divisions found
              </ArgonTypography>
            </TableCell>
          </TableRow>
        )}
        {/* </TableBody> */}
      </Table>
    </>
  );
}

AutoInvitationDivisionMapping.propTypes = {};

export default AutoInvitationDivisionMapping;
